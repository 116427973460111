<!--
 	使用方式请参考 @/views/shareAndDispatch/workManager/record/index .vue
  -->

<template>
  <div class="date-time-range">
    <p
      class="time-panel"
      :class="{ 'have-value': startTime }"
      @click="startTimeEvent"
    >
      {{ startTime ? startTimeText : placeholder[0] }}
    </p>
    <p class="time-range"></p>
    <p
      class="time-panel"
      :class="{ 'have-value': endTime }"
      @click="endTimeEvent"
    >
      {{ endTime ? endTimeText : placeholder[1] }}
    </p>

    <van-popup
      v-model="visible"
      round
      get-container="body"
      position="bottom"
      :style="{ height: popupHeight }"
    >
      <div class="date-time-range-popup">
<!--        <van-datetime-picker-->
<!--          v-model="selectTime"-->
<!--          :show-toolbar="datetimeToolbar"-->
<!--          :type="type"-->
<!--          :title="title"-->
<!--          v-bind="$attrs"-->
<!--          v-on="$listeners"-->
<!--          :min-date="minDate"-->
<!--          :max-date="maxDate"-->
<!--          @change="changeEvent"-->
<!--          @confirm="pickerConfirm"-->
<!--		  @pickerCancel="pickerCancel"-->
<!--        />-->


        <van-datetime-picker
            v-model="selectTime"
            :show-toolbar="datetimeToolbar"
            :type="type"
            :title="title"
            v-bind="$attrs"
            v-on="$listeners"
            :min-date="minDate2"
            :max-date="maxDate2"
            @change="changeEvent"
            @confirm="pickerConfirm"
            @pickerCancel="pickerCancel"
        />
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  name: "dateTimeRange",
  components: {},
  model: {
    prop: "value",
    event: "changeValue",
  },
  props: {
    value: {
      // 绑定值为时间戳格式
      type: [Array, String, Object],
      default: "",
    },
    placeholder: {
      type: Array,
      default: () => {
        return ["开始时间", "结束时间"];
      },
    },
    // DatetimePicker 的 type
    type: {
      type: String,
      default: "date",
    },
    popupHeight: {
      type: String,
      default: "40%",
    },
    format: {
      // 显示选中结果格式函数(未设置默认展示日期)
      type: [Function, String],
      default: "",
    },
    minIsNewDate: {
      type: Boolean,
      default: false,
    },
    datetimeToolbar: {
      type: Boolean,
      default: false,
    },
    title: {
      // 绑定值为时间戳格式
      type: [Array, String, Object],
      default: "",
    },
  },
  data() {
    return {
      visible: false,

      startTime: "",
      endTime: "",

      seletType: "",
      selectTime: "",
      minDate2: new Date(),
      maxDate2: new Date(new Date().getTime() + 30 * 24 * 60 * 60 * 1000),
      minDate: new Date(new Date().getFullYear() - 10 + "-01-01"),
      maxDate: new Date(new Date().getFullYear() + 10 + "-12-31"),
      minHour: 0,
      maxHour: 23,
      minMinute: 0,
      maxMinute: 59,
    };
  },
  computed: {
    startTimeText() {
      if (!this.format || typeof this.format != "function") {
        return this.$filters.formatDate(this.startTime, "");
      } else {
        return this.format(this.startTime);
      }
    },
    endTimeText() {
      if (!this.format || typeof this.format != "function") {
        return this.$filters.formatDate(this.endTime, "");
      } else {
        return this.format(this.endTime);
      }
    },
  },
  watch: {
    value: {
      handler() {
        if (!this.value || !this.value.length) {
          this.startTime = "";
          this.endTime = "";
        } else {
          if (this.value[0]) this.startTime = new Date(this.value[0]);
          else this.startTime = "";
          if (this.value[1]) this.endTime = new Date(this.value[1]);
          else this.endTime = "";
        }
      },
      immediate: true,
      deep: true,
    },
  },

  created() {
    if (this.minIsNewDate) {
      this.minDate = new Date();
    }
  },
  mounted() {},
  methods: {
    setDefaultMinTime() {
      this.minDate = new Date(new Date().getFullYear() - 10 + "-01-01");
      if (this.minIsNewDate) {
        this.minDate = new Date();
      }
      this.minHour = 0;
      this.minMinute = 0;
    },
    setDefaultMaxTime() {
      this.maxDate = new Date(new Date().getFullYear() + 10 + "-12-31");
      this.maxHour = 23;
      this.maxMinute = 59;
    },

    startTimeEvent() {
      this.seletType = "startTime";


      if (this.endTime) {
        // 有结束时间
        const date = new Date(this.endTime);
        const Y = date.getFullYear();
        const M =
          date.getMonth() + 1 < 10
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1;
        const D = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
        const h = date.getHours();
        const m = date.getMinutes();

        this.maxDate = new Date(Y + "-" + M + "-" + D + " " + h + ":" + m);
        this.maxHour = h;
        this.maxMinute = m;
        this.setDefaultMinTime();
      }
      // this.changeEvent();
      this.visible = true;

      setTimeout(() => {
        if (this.startTime) {
          this.selectTime = this.startTime;
        } else {
          if (this.endTime) {
            this.selectTime = this.endTime;
          } else {
            this.selectTime = new Date();
          }
        }
      }, 200)
    },
    endTimeEvent() {
      this.seletType = "endTime";


      if (this.startTime) {
        // 有开始时间
        const date = new Date(this.startTime);
        const Y = date.getFullYear();
        const M =
          date.getMonth() + 1 < 10
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1;
        const D = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
        const h = date.getHours();
        const m = date.getMinutes();

        this.minDate = new Date(Y + "-" + M + "-" + D + " " + h + ":" + m);
        this.minHour = h;
        this.minMinute = m;
        this.setDefaultMaxTime();
      }
      // this.changeEvent();
      this.visible = true;
      setTimeout(() => {
        if (this.endTime) {
          this.selectTime = this.endTime;
        } else {
          if (this.startTime) {
            this.selectTime = this.startTime;
          } else {
            this.selectTime = new Date();
          }
        }
      }, 200)
    },
    changeEvent() {
      if (this.datetimeToolbar) {
        return;
      }
	  this.pickerChange()
    },
    pickerChange() {
      this[this.seletType] = new Date(this.selectTime);
      const startTime = this.startTime ? this.startTime.getTime() : "";
      const endTime = this.endTime ? this.endTime.getTime() : "";
      this.$emit("changeValue", [startTime, endTime]);
      this.$nextTick(() => {
        this.$emit("change", this.value);
      });
    },
    pickerConfirm() {
      this.visible = false;
	  this.pickerChange()
    },
	pickerCancel() {
      this.visible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@styles/variables.scss";
.date-time-range {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  .time-panel {
    width: calc((100% - 28px) / 2);
    height: 32px;
    line-height: 30px;
    border-radius: 32px;
    background: #eee;
    border: 1px solid #eee;
    font-size: 14px;
    color: #999;
    text-align: center;
    cursor: pointer;
    &.have-value {
      background: #d8e3ff;
      color: #3e73fb;
      border-color: #3e73fb;
    }
  }
  .time-range {
    width: 28px;
    position: relative;
    height: 32px;
    &::after {
      content: "";
      width: 50%;
      height: 1px;
      background: #999;
      position: absolute;
      left: 25%;
      right: 25%;
      top: 50%;
    }
  }
}

.date-time-range-popup {
  padding: 20px;
  background: #fff;
}
</style>
