<template>
	<div class="page-wrap">
		<div class="page-header">
			<div class="search-wrap"><searchInput v-model="baseData.query" @search="searchEvent"></searchInput></div>
			<button type="button" class="filter-btn" @click="targetFilterEvent">
				筛选
				<van-icon name="arrow-down" />
			</button>
		</div>

		<filterPopup ref="filterPopup" height="50%" title="筛选" @onReset="onReset" @onConfirm="searchEvent">
			<div class="filter-container">
				<filterPopupItem label="设备科室">
					<div class="select-item" @click="tenantSelectEvent">{{ baseData.tenantName }}</div>
					<KcCascader
						ref="tenantSelect"
						v-model="baseData.tenantId"
						:options="baseOptions.tenantOptions"
						:props="{ label: 'name', value: 'id' }"
						@submit="tenantChange"
					></KcCascader>
				</filterPopupItem>

				<filterPopupItem label="设备分类">
					<div class="select-item" @click="categorySelectEvent">{{ baseData.categoryName }}</div>
					<KcCascader
						ref="categorySelect"
						v-model="baseData.categoryId"
						:options="baseOptions.categoryOptions"
						:props="{ label: 'name', value: 'id' }"
						@submit="categoryChange"
					></KcCascader>
				</filterPopupItem>
			</div>
		</filterPopup>

		<tabs1 v-model="activeTab" :options="tabList" @change="tabChange"></tabs1>

		<div class="page-container"><router-view /></div>
	</div>
</template>

<script>
import dateTimeRange from '@components/dateTimeRange';
import { getDate } from '@utils/utils.js';
import tenantBaseDataMixinAll from '@/mixins/tenantMixins/tenantBaseDataMixin-all.js';
export default {
	name: 'shareOrder',
	components: { dateTimeRange },
	provide() {
		return {
			shareOrderBaseData: this.baseData
		};
	},
	mixins: [tenantBaseDataMixinAll],
	data() {
		return {
			baseData: {
				query: '',
				tenantId: '',
				tenantName: '全部科室',
				categoryId: '',
				categoryName: '全部分类'
			},
			baseOptions: {
				tenantOptions: [
					{
						id: '',
						name: '全部科室'
					}
				],
				categoryOptions: [
					{
						id: '',
						name: '全部分类'
					}
				]
			},

			activeTab: '/deviceView1/share/shareOrder/shareOrder1',

			tabList: [
				{
					title: '预约',
					path: '/deviceView1/share/shareOrder/shareOrder1'
				},
				{
					title: '锁定',
					path: '/deviceView1/share/shareOrder/shareOrder6'
				},
				{
					title: '待审批',
					path: '/deviceView1/share/shareOrder/shareOrder2'
				},
				{
					title: '已审批',
					path: '/deviceView1/share/shareOrder/shareOrder7'
				},
				{
					title: '报障',
					path: '/deviceView1/share/shareOrder/shareOrder3'
				},
				{
					title: '借用中',
					path: '/deviceView1/share/shareOrder/shareOrder5'
				},
				{
					title: '已归还',
					path: '/deviceView1/share/shareOrder/shareOrder4'
				},
			]
		};
	},
	created() {
		this.activeTab = this.$route.path;
		this.getDeviceCategoryTree();
	},
	mounted() {},
	methods: {
		targetFilterEvent() {
			this.$refs.filterPopup.show();
		},
		onReset() {
			this.baseData.tenantId = '';
			this.baseData.categoryId = '';
			this.baseData.tenantName = '全部科室';
			this.baseData.categoryName = '全部分类';
		},
		searchEvent() {
			this.$refs.filterPopup.hide();
			this.$bus.emit('shareOrderSearch');
		},
		tenantSelectEvent() {
			this.$refs.tenantSelect.show();
		},
		tenantChange(e, item) {
			this.baseData.tenantName = item.name;
		},
		getDeviceCategoryTree() {
			this.$api.assetModule
				.getDeviceCategoryTree()
				.then(res => {
					res.data.unshift({
						id: '',
						name: '全部分类'
					});
					this.baseOptions.categoryOptions = res.data;
				})
				.catch(() => {});
		},
		categorySelectEvent() {
			this.$refs.categorySelect.show();
		},
		categoryChange(e, item) {
			this.baseData.categoryName = item.name;
		},

		tabChange(e) {
			this.activeTab = e;
			this.$router.replace(e).catch(() => {});
		}
	}
};
</script>

<style lang="scss" scoped>
@import '@styles/variables.scss';
@import '@styles/layout.scss';
.page-wrap {
	height: 100%;
	background: linear-gradient(360deg, rgba(114, 171, 253, 0) 0%, #568efc 70%, #3e73fb 100%);
	background-size: 100% 195px;
	background-repeat: no-repeat;

	.page-header {
		margin-bottom: 12px;
		padding: 8px 16px 0 16px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		.search-wrap {
			width: calc(100% - 50px);
		}
		.filter-btn {
			font-size: 12px;
			color: #fff;
			background: none;
			line-height: 32px;
			.van-icon {
				margin-left: 4px;
			}
		}
	}

	.page-container {
		height: calc(100% - 32px - 52px - 20px);
		margin-top: 10px;
		overflow-y: auto;
	}
}

.filter-container {
	.select-item {
		display: inline-block;
		line-height: 30px;
		background: #d8e3ff;
		color: #3e73fb;
		border: 1px solid #3e73fb;
		border-radius: 32px;
		padding: 0 20px;
		max-width: 100%;
		font-size: 14px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
}
</style>
